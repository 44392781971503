var screenMdMaxWidth = 1199;
var screenSmMaxWidth = 991;
var screenXsMaxWidth = 767;
var screenXxsMaxWidth = 320;
var scrollbarWidth, previousWidth, currentWidth;

document.addEventListener("DOMContentLoaded", ready);
function ready() {
    scrollbarWidth = getScrollbarWidth();
    currentWidth = previousWidth = document.body.clientWidth + scrollbarWidth;
    // console.log('currentWidth', currentWidth);
    // console.log('scrollbarWidth', scrollbarWidth);

    var event = new Event("DOMContentLoadedReady");
    window.dispatchEvent(event);
}

function getScrollbarWidth() {
    var div = document.createElement('div');
    div.style.overflowY = 'scroll';
    div.style.width = '50px';
    div.style.height = '50px';
    div.style.visibility = 'hidden';
    document.body.appendChild(div);
    var scrollWidth = div.offsetWidth - div.clientWidth;
    //console.log('offsetWidth', div.offsetWidth, 'clientWidth', div.clientWidth, 'scrollWidth', scrollWidth);
    document.body.removeChild(div);
    return scrollWidth;
}

function changedSize(previousWidth, currentWidth) {
    //console.log('changedSize: previousWidth =', previousWidth, ' currentWidth =', currentWidth);
    if ((currentWidth <= screenMdMaxWidth && previousWidth > screenMdMaxWidth)
        || (currentWidth > screenMdMaxWidth && previousWidth <= screenMdMaxWidth)
        || (currentWidth <= screenSmMaxWidth && previousWidth > screenSmMaxWidth)
        || (currentWidth > screenSmMaxWidth && previousWidth <= screenSmMaxWidth)
        || (currentWidth <= screenXsMaxWidth && previousWidth > screenXsMaxWidth)
        || (currentWidth > screenXsMaxWidth && previousWidth <= screenXsMaxWidth)
    ) {
        return true;
    }

    return false;
}


$(window).resize(function () {
    scrollbarWidth = getScrollbarWidth();
    currentWidth = $(window).width() + scrollbarWidth;

    if (changedSize(previousWidth, currentWidth)) {
        var event = new Event("windowResizeBreakpointChanged");
        window.dispatchEvent(event);

    } else if (previousWidth <= screenXsMaxWidth && currentWidth <= screenXsMaxWidth) {
        var event = new Event("windowResizeInsideXS");
        window.dispatchEvent(event);
    }

    previousWidth = currentWidth;
});